import React, { useState } from 'react';

const Background = () => {
  console.log("Background component rendering");
  // Increased number of lines and decreased number of dots for better performance
  const [lines] = useState(Array.from({ length: 30 }));
  const [dots] = useState(Array.from({ length: 40 }));

  return (
    <div className="fixed inset-0 w-full h-full bg-black/95 z-0">
      {/* Matrix Rain Effect */}
      <div className="absolute inset-0 overflow-hidden">
        {lines.map((_, index) => (
          <div
            key={`line-${index}`}
            className="absolute h-full w-px"
            style={{
              left: `${(index + 1) * 3.33}%`,
              animation: `matrixRain ${10 + index % 3}s ${index * 0.2}s infinite linear`,
              background: 'linear-gradient(180deg, transparent 0%, #4ade80 50%, transparent 100%)',
              opacity: 0.5,
            }}
          />
        ))}
      </div>

      {/* Floating Dots */}
      <div className="absolute inset-0">
        {dots.map((_, index) => {
          const size = Math.random() * 4 + 2; // Increased dot size
          return (
            <div
              key={`dot-${index}`}
              className="absolute rounded-full"
              style={{
                width: `${size}px`,
                height: `${size}px`,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                background: '#4ade80',
                boxShadow: '0 0 8px #4ade80',
                animation: `float ${Math.random() * 15 + 15}s ${Math.random() * 5}s infinite linear`,
                opacity: 0.6,
              }}
            />
          );
        })}
      </div>

      {/* Pulsing Gradient Effect */}
      <div className="absolute inset-0 animate-pulse">
        <div className="absolute inset-0 bg-gradient-to-b from-black/30 via-transparent to-black/30" />
        <div className="absolute inset-0 bg-gradient-to-r from-black/30 via-transparent to-black/30" />
      </div>

      {/* Additional Ambient Light Effect */}
      <div className="absolute inset-0">
        {[...Array(4)].map((_, index) => (
          <div
            key={`ambient-${index}`}
            className="absolute rounded-full blur-3xl"
            style={{
              width: '30vw',
              height: '30vw',
              left: `${25 + (index % 2) * 50}%`,
              top: `${25 + Math.floor(index / 2) * 50}%`,
              transform: 'translate(-50%, -50%)',
              background: '#4ade80',
              opacity: 0.03,
              animation: `ambientPulse ${10 + index * 2}s infinite ease-in-out`,
            }}
          />
        ))}
      </div>

      <style>
        {`
          @keyframes matrixRain {
            0% {
              transform: translateY(-100%);
              opacity: 0;
            }
            10% {
              opacity: 0.5;
            }
            90% {
              opacity: 0.5;
            }
            100% {
              transform: translateY(100%);
              opacity: 0;
            }
          }

          @keyframes float {
            0%, 100% {
              transform: translate(0, 0);
              opacity: 0.3;
            }
            25% {
              transform: translate(100px, 100px);
              opacity: 0.6;
            }
            50% {
              transform: translate(0, 200px);
              opacity: 0.4;
            }
            75% {
              transform: translate(-100px, 100px);
              opacity: 0.6;
            }
          }

          @keyframes ambientPulse {
            0%, 100% {
              opacity: 0.02;
              transform: translate(-50%, -50%) scale(1);
            }
            50% {
              opacity: 0.04;
              transform: translate(-50%, -50%) scale(1.2);
            }
          }
        `}
      </style>
    </div>
  );
};

export default Background;