import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUsers,
  faChartLine,
  faHandshake,
  faWrench,
  faShield,
  faBullseye,
  faStar,
  faGraduationCap,
  faMessage,
  faArrowRight,
  faDumbbell,
  faGaugeHigh,
  faListCheck
} from '@fortawesome/free-solid-svg-icons';

const Index = () => {
  const [counts, setCounts] = useState({
    projects: 0,
    onTime: 0,
    retention: 0
  });

  const services = [
    { title: 'Property Listing Management', icon: faHome },
    { title: 'Tenant Database Management', icon: faUsers },
    { title: 'Lead Pipeline Tracking', icon: faChartLine },
    { title: 'Agent Collaboration', icon: faHandshake },
    { title: 'Property Management Support', icon: faWrench }
  ];

  const techBenefits = [
    {
      icon: faDumbbell,
      title: 'Let Technology Do The Heavy Lifting',
      description: 'Our solutions make your real estate work simpler - it helps you handle listings, track deals, and grow your business. Made to help you do what you do best.'
    },
    {
      icon: faGaugeHigh,
      title: 'Your Speed, Our Stability',
      description: 'Technology that keeps up with your business.'
    },
    {
      icon: faListCheck,
      title: `If you have little to do, it's not a problem`,
      description: `However, if you're busy... this is not feasible.`
    }
  ];

  const features = [
    {
      icon: faShield,
      title: 'GUARANTEE',
      description: `We only win if you win. That's the basis for a good partnership.`
    },
    {
      icon: faBullseye,
      title: 'RESULT',
      description: 'Our first priority is to get you results. Less talk, more walk.'
    },
    {
      icon: faStar,
      title: 'Top Priority',
      description: 'Your success gets our full focus and expertise.'
    },
    {
      icon: faGraduationCap,
      title: 'SPECIALIZATION',
      description: `SPECIALIZATION Jack of all trades... Master of none. Specialization works. That's why we work with industries we know, so we can guarantee results.`
    }
  ];

  const statistics = [
    { key: 'projects', number: 5, label: 'Projects Launched', suffix: '+' },
    { key: 'onTime', number: 99, label: 'On-Time Delivery', suffix: '%' },
    { key: 'retention', number: 99, label: 'Client Retention', suffix: '%' }
  ];

  // Create refs for individual card containers
  const serviceCardsRef = useRef([]);
  const techBenefitsRef = useRef([]);
  const featureCardsRef = useRef([]);
  const statsRef = useRef(null);

  const startCounting = () => {
    statistics.forEach(stat => {
      let start = 0;
      const end = stat.number;
      const duration = 2000;
      const increment = end / (duration / 16);
      let currentCount = 0;

      const timer = setInterval(() => {
        currentCount += increment;
        if (currentCount >= end) {
          currentCount = end;
          clearInterval(timer);
        }
        setCounts(prev => ({
          ...prev,
          [stat.key]: Math.floor(currentCount)
        }));
      }, 16);
    });
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersect = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target === statsRef.current) {
            startCounting();
          }
          entry.target.classList.add('animate-in');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);

    // Observe all cards
    serviceCardsRef.current.forEach(card => {
      if (card) observer.observe(card);
    });

    techBenefitsRef.current.forEach(card => {
      if (card) observer.observe(card);
    });

    featureCardsRef.current.forEach(card => {
      if (card) observer.observe(card);
    });

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const messageButtonClasses = `
    group relative px-8 py-4 bg-transparent text-green-500 font-bold
    border-2 border-green-500 rounded-2xl
    transform hover:scale-105
    transition-all duration-300 text-lg
    hover:bg-green-500/10 hover:border-green-400
    hover:text-green-400 hover:shadow-lg 
    hover:shadow-green-500/30
    focus:outline-none focus:ring-2 focus:ring-green-500 
    focus:ring-offset-2 focus:ring-offset-transparent 
    active:scale-95 backdrop-blur-sm
  `;

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-secondary to-black">
      {/* Animated background lines */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden">
        {[...Array(10)].map((_, i) => (
          <div
            key={i}
            className="absolute h-screen w-px bg-gradient-to-b from-green-500/0 via-green-500/10 to-green-500/0"
            style={{
              left: `${i * 10}%`,
              animation: `moveVerticalLine ${3 + i * 0.5}s infinite linear`,
              opacity: 0.1 + (i * 0.05)
            }}
          />
        ))}
      </div>

      {/* Hero Section */}
      <section className="min-h-screen flex items-center justify-center relative pt-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center animate-fade-in">
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-6">
              <span className="text-green-500 hover:opacity-90 transition-opacity duration-300">Scale.</span>{' '}
              <span className="text-white hover:text-green-500 transition-colors duration-300">Sell.</span>{' '}
              <span className="text-white hover:text-green-500 transition-colors duration-300">Excel.</span>
            </h1>
            <p className="text-lg md:text-xl mb-8 text-gray-300">
              ...However, there are already 101 things on your to-do list.
              And they are all important!
            </p>
            <Link to="/contact">
              <button className={messageButtonClasses}>
                <div className="flex items-center justify-center space-x-2">
                  <FontAwesomeIcon 
                    icon={faMessage} 
                    className="transform group-hover:rotate-12 transition-transform duration-300"
                  />
                  <span>Message Us</span>
                  <FontAwesomeIcon 
                    icon={faArrowRight}
                    className="transform translate-x-0 opacity-0 group-hover:translate-x-1 group-hover:opacity-100 transition-all duration-300"
                  />
                </div>
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 relative">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-green-500 hover:text-white transition-colors duration-300">
            Drowning in admin work?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={service.title}
                ref={el => serviceCardsRef.current[index] = el}
                className="p-6 bg-black/30 backdrop-blur-sm border border-green-500/20 rounded-xl
                  hover:border-green-500/50 transition-all duration-500
                  transform hover:-translate-y-1 opacity-0 translate-y-10"
                style={{ 
                  transitionDelay: `${index * 100}ms`
                }}
              >
                <div className="text-4xl mb-4 transform transition-transform duration-300 hover:scale-110 text-green-500">
                  <FontAwesomeIcon icon={service.icon} />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-green-500 hover:text-white
                  transition-colors duration-300">
                  {service.title}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Tech Benefits Section */}
      <section className="py-20 relative border-t border-green-500/10">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {techBenefits.map((benefit, index) => (
              <div
                key={benefit.title}
                ref={el => techBenefitsRef.current[index] = el}
                className="p-6 bg-black/30 backdrop-blur-sm rounded-xl border border-green-500/20
                  hover:border-green-500/50 transition-all duration-500
                  transform hover:-translate-y-1 opacity-0 translate-y-10"
                style={{ 
                  transitionDelay: `${index * 100}ms`
                }}
              >
                <div className="text-4xl mb-4 transform transition-transform duration-300 hover:scale-110 text-green-500">
                  <FontAwesomeIcon icon={benefit.icon} />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-green-500 hover:text-white
                  transition-colors duration-300">
                  {benefit.title}
                </h3>
                <p className="text-gray-300 hover:text-white transition-colors duration-300">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 relative border-t border-green-500/10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-green-500 hover:text-white transition-colors duration-300">
            What Makes Us Different?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div
                key={feature.title}
                ref={el => featureCardsRef.current[index] = el}
                className="p-6 bg-black/30 backdrop-blur-sm rounded-xl border border-green-500/20
                  hover:border-green-500/50 transition-all duration-500
                  transform hover:-translate-y-1 opacity-0 translate-y-10"
                style={{ 
                  transitionDelay: `${index * 100}ms`
                }}
              >
                <div className="text-4xl mb-4 transform transition-transform duration-300 hover:scale-110 text-green-500">
                  <FontAwesomeIcon icon={feature.icon} />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-green-500 hover:text-white
                  transition-colors duration-300">
                  {feature.title}
                </h3>
                <p className="text-gray-300 hover:text-white transition-colors duration-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Statistics Section */}
      <section className="py-20 relative border-t border-green-500/10" ref={statsRef}>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            {statistics.map((stat) => (
              <div
                key={stat.label}
                className="transform hover:scale-105 transition-all duration-300"
              >
                <div className="text-4xl font-bold mb-2 text-green-500">
                  {counts[stat.key]}{stat.suffix}
                </div>
                <div className="text-gray-300 hover:text-white transition-colors duration-300">
                  {stat.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-20 relative border-t border-green-500/10">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <Link to="/contact">
              <button className={messageButtonClasses}>
                <div className="flex items-center justify-center space-x-2">
                  <span>Get Started</span>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Animation keyframes */}
      <style>
        {`
          @keyframes moveVerticalLine {
            0% {
              transform: translateY(-100%);
            }
            100% {
              transform: translateY(100%);
            }
          }
          @keyframes float {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-20px);
            }
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          .animate-fade-in {
            animation: fadeIn 1s ease-out forwards;
          }
          .animate-in {
            opacity: 1 !important;
            transform: translateY(0) !important;
          }
        `}
      </style>
    </div>
  );
};

export default Index;