import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Background from './components/Background';
import Header from './components/Header';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Index from './pages';
import Loader from './components/Loader';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate initial loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500); // Adjust timing as needed

    return () => clearTimeout(timer);
  }, []);

  // Show loader while loading
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="relative min-h-screen w-full overflow-x-hidden bg-black">
      {/* Background Layer */}
      <Background />
      
      {/* Content Layer */}
      <div className="relative z-10 flex flex-col min-h-screen">
        <Header />
        
        {/* Main content */}
        <main className="flex-grow relative pt-20">
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        
        <Footer />
      </div>
    </div>
  );
};

export default App;