import React, { useState } from 'react';

const Contact = () => {
  const [formStatus, setFormStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://formcarry.com/s/aGO9bi7uPXE', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setFormStatus('Message sent successfully!');
        setFormData({
          name: '',
          phone_number: '',
          email: '',
          message: ''
        });
      } else {
        setFormStatus('There was an error sending the message. Please try again.');
      }
    } catch (error) {
      setFormStatus('There was an error sending the message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const inputClasses = `
    w-full px-4 py-3 rounded-lg bg-black/30 border border-green-500/20
    text-white placeholder-gray-400
    focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent
    transition-all duration-300 backdrop-blur-sm
    hover:border-green-500/40
  `;

  const labelClasses = "block text-gray-300 mb-2 font-medium";

  return (
    <section className="relative min-h-screen pt-20 pb-20 z-10">
      {/* Content Container */}
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4 text-white">
              Get in Touch
            </h2>
            <p className="text-gray-400 text-lg">
              Drop us a message and we'll get back to you as soon as possible.
            </p>
          </div>

          {/* Form Card */}
          <div className="relative bg-black/40 backdrop-blur-md rounded-2xl 
                        border border-green-500/20 p-8 shadow-xl
                        hover:border-green-500/30 transition-all duration-500">
            {/* Ambient Glow */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-b from-green-500/5 to-transparent" />
            
            {/* Form */}
            <form onSubmit={handleSubmit} className="space-y-6 relative">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className={labelClasses} htmlFor="name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={inputClasses}
                    placeholder="John Doe"
                    required
                  />
                </div>

                <div>
                  <label className={labelClasses} htmlFor="phone_number">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone_number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    className={inputClasses}
                    placeholder="+1 (555) 000-0000"
                    required
                  />
                </div>
              </div>

              <div>
                <label className={labelClasses} htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={inputClasses}
                  placeholder="john@example.com"
                  required
                />
              </div>

              <div>
                <label className={labelClasses} htmlFor="message">
                  Your Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className={`${inputClasses} resize-none`}
                  placeholder="How can we help you?"
                  required
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`
                  w-full px-6 py-3 rounded-lg font-semibold
                  bg-green-500 text-black
                  hover:bg-green-400 
                  transform hover:scale-[1.02]
                  transition-all duration-300
                  disabled:opacity-50 disabled:cursor-not-allowed
                  focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-black
                `}
              >
                {loading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Sending...
                  </span>
                ) : (
                  'Send Message'
                )}
              </button>
            </form>

            {/* Status Message */}
            {formStatus && (
              <div
                className={`
                  mt-6 p-4 rounded-lg text-center
                  transform transition-all duration-300
                  ${
                    formStatus.includes('successfully')
                      ? 'bg-green-500/10 text-green-400 border border-green-500/20'
                      : 'bg-red-500/10 text-red-400 border border-red-500/20'
                  }
                `}
              >
                {formStatus}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Bottom Gradient */}
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black/50 to-transparent" />
    </section>
  );
};

export default Contact;