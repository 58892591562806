import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

const Header = () => {
    const [visible, setVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            // Immediately show header on any upward scroll
            if (currentScrollY < lastScrollY) {
                setVisible(true);
            }
            // Hide header only when scrolling down and not at the top
            else if (currentScrollY > lastScrollY && currentScrollY > 0) {
                setVisible(false);
            }
            setLastScrollY(currentScrollY);
        };

        // Add scroll event listener with options for better performance
        window.addEventListener('scroll', handleScroll, { passive: true });

        // Cleanup function to remove event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]); // Only re-run effect if lastScrollY changes

    return (
        <header
            className={`
                fixed w-full z-50
                transition-transform duration-200 ease-in-out
                ${visible ? 'translate-y-0' : '-translate-y-full'}
            `}
        >
            <div className="border-b border-green-500/10 backdrop-blur-sm bg-black/50">
                <div className="container mx-auto px-4">
                    <div className="flex justify-center items-center h-20"> {/* Changed: added justify-center */}
                        <Link
                            to="/"
                            className="relative group hover:opacity-80 transition-all duration-300"
                        >
                            {/* Base glow effect */}
                            <div
                                className="
                                    absolute -inset-1
                                    rounded-full blur-md
                                    bg-green-500/20
                                    group-hover:bg-green-500/30
                                    transition-all duration-300
                                    animate-pulse
                                "
                            />
                            {/* Matrix theme glow effect */}
                            <div
                                className="
                                    absolute -inset-2
                                    rounded-full blur-lg
                                    bg-green-500/10
                                    group-hover:bg-green-500/20
                                    transition-all duration-300
                                "
                                style={{
                                    animation: 'matrixGlow 3s infinite ease-in-out'
                                }}
                            />
                            {/* Logo */}
                            <img
                                src={logo}
                                alt="Company Logo"
                                className="h-12 w-auto relative"
                                loading="eager"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <style>
                {`
                    @keyframes matrixGlow {
                        0%, 100% {
                            opacity: 0.3;
                            transform: scale(1);
                        }
                        50% {
                            opacity: 0.5;
                            transform: scale(1.05);
                        }
                    }
                `}
            </style>
        </header>
    );
};

export default Header;