import React from 'react';

const Loader = () => {
  return (
    <div className="fixed inset-0 w-full h-full bg-black/95 z-50 flex flex-col items-center justify-center">
      {/* Matrix Rain Effect */}
      <div className="absolute inset-0 overflow-hidden">
        {Array.from({ length: 20 }).map((_, index) => (
          <div
            key={`loader-line-${index}`}
            className="absolute h-full w-px"
            style={{
              left: `${(index + 1) * 5}%`,
              animation: `loaderMatrixRain ${8 + index % 3}s ${index * 0.1}s infinite linear`,
              background: 'linear-gradient(180deg, transparent 0%, #4ade80 50%, transparent 100%)',
              opacity: 0.5,
            }}
          />
        ))}
      </div>
      
      {/* Loading Text */}
      <div className="relative z-10 text-center">
        <h2 className="text-4xl font-bold text-green-400 mb-4 animate-pulse">
          Loading...
        </h2>
        <div className="flex space-x-2 justify-center">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={`dot-${index}`}
              className="w-3 h-3 bg-green-400 rounded-full"
              style={{
                animation: `loadingDot 1.5s ${index * 0.2}s infinite ease-in-out`,
              }}
            />
          ))}
        </div>
      </div>

      {/* Ambient Light */}
      <div className="absolute inset-0">
        <div
          className="absolute rounded-full blur-3xl"
          style={{
            width: '40vw',
            height: '40vw',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#4ade80',
            opacity: 0.05,
            animation: 'loaderAmbientPulse 2s infinite ease-in-out',
          }}
        />
      </div>

      <style>
        {`
          @keyframes loaderMatrixRain {
            0% {
              transform: translateY(-100%);
              opacity: 0;
            }
            10% {
              opacity: 0.5;
            }
            90% {
              opacity: 0.5;
            }
            100% {
              transform: translateY(100%);
              opacity: 0;
            }
          }

          @keyframes loadingDot {
            0%, 100% {
              transform: scale(0.3);
              opacity: 0.3;
            }
            50% {
              transform: scale(1);
              opacity: 1;
            }
          }

          @keyframes loaderAmbientPulse {
            0%, 100% {
              opacity: 0.03;
              transform: translate(-50%, -50%) scale(1);
            }
            50% {
              opacity: 0.07;
              transform: translate(-50%, -50%) scale(1.2);
            }
          }
        `}
      </style>
    </div>
  );
};

export default Loader;