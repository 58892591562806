import React, { useState } from 'react';
import logo from '../assets/logo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [subscribeStatus, setSubscribeStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://formcarry.com/s/aGO9bi7uPXE', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setSubscribeStatus('Successfully subscribed to newsletter!');
        setEmail('');
      } else {
        setSubscribeStatus('Subscription failed. Please try again.');
      }
    } catch (error) {
      setSubscribeStatus('Subscription failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="bg-gradient-to-b from-secondary to-black text-gray-300 border-t border-gray-800">
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col items-center space-y-8">
          {/* Logo with Glow Effect */}
          <div className="relative group hover:scale-105 transition-transform duration-300 cursor-pointer">
            {/* Glow effect container */}
            <div className="absolute inset-0 rounded-full blur-md bg-green-500/20 group-hover:bg-green-500/30 transition-all duration-300 animate-pulse" />
            
            {/* Additional glow layer */}
            <div 
              className="absolute inset-0 rounded-full blur-lg bg-green-500/10 group-hover:bg-green-500/20 transition-all duration-300"
              style={{
                animation: 'matrixGlow 3s infinite ease-in-out'
              }}
            />
            
            {/* Logo image */}
            <img
              src={logo}
              alt="Company Logo"
              className="h-12 w-auto relative"
            />
          </div>

          {/* Newsletter Subscription */}
          <div className="w-full max-w-md">
            <form onSubmit={handleSubscribe} className="flex flex-col space-y-4">
              <label className="text-center text-lg font-medium text-gray-300">
                Subscribe to Our Newsletter
              </label>
              <div className="flex">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="flex-1 px-4 py-2 rounded-l-lg bg-black/30 border border-green-500/20
                    text-white placeholder-gray-400
                    focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent
                    transition-all duration-300 backdrop-blur-sm
                    hover:border-green-500/40"
                  required
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="px-6 py-2 rounded-r-lg font-semibold
                    bg-green-500 text-black
                    hover:bg-green-400 
                    transform hover:scale-[1.02]
                    transition-all duration-300
                    disabled:opacity-50 disabled:cursor-not-allowed
                    focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-black"
                >
                  {loading ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </span>
                  ) : (
                    'Subscribe'
                  )}
                </button>
              </div>
              {subscribeStatus && (
                <div
                  className={`
                    p-3 rounded-lg text-center text-sm
                    transform transition-all duration-300
                    ${
                      subscribeStatus.includes('Successfully')
                        ? 'bg-green-500/10 text-green-400 border border-green-500/20'
                        : 'bg-red-500/10 text-red-400 border border-red-500/20'
                    }
                  `}
                >
                  {subscribeStatus}
                </div>
              )}
            </form>
          </div>

          {/* Contact Information */}
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8">
            {/* Email */}
            <a
              href="mailto:zaiban@greeencode.com"
              className="flex items-center space-x-2 group hover:scale-105 transition-all duration-300"
            >
              <svg
                className="w-5 h-5 text-primary group-hover:animate-bounce"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <span className="hover:text-primary transition-colors duration-200">
                zaiban@greeencode.com
              </span>
            </a>
            {/* Phone */}
            <a
              href="tel:+917760950314"
              className="flex items-center space-x-2 group hover:scale-105 transition-all duration-300"
            >
              <svg
                className="w-5 h-5 text-primary group-hover:animate-bounce"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <span className="hover:text-primary transition-colors duration-200">
                +91 7760950314
              </span>
            </a>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-6">
            {/* House Icon */}
            <a
              href="#"
              className="transform hover:scale-125 transition-transform duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="w-6 h-6 text-gray-400 hover:text-primary"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </a>
            {/* LinkedIn */}
            <a
              href="#"
              className="transform hover:scale-125 transition-transform duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="w-6 h-6 text-gray-400 hover:text-primary"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
              </svg>
            </a>
          </div>

          {/* Copyright */}
          <div className="text-sm text-gray-400 pt-8 border-t border-gray-800 w-full text-center">
            Copyright © {currentYear}. All Rights Reserved.
          </div>
        </div>
      </div>

      {/* Matrix Glow Animation */}
      <style>
        {`
          @keyframes matrixGlow {
            0%, 100% {
              opacity: 0.3;
              transform: scale(1);
            }
            50% {
              opacity: 0.5;
              transform: scale(1.05);
            }
          }
        `}
      </style>
    </footer>
  );
};

export default Footer;